import React from 'react'
import { useParams } from 'react-router-dom'
import { Text, TouchableOpacity, View } from 'react-native';
import AppStore from './res/apple-store.png'
import PlayStore from './res/play-store.png'
import LogoYellow from './res/logoYellow.png'

interface Params {
   roomId: string,
}

export default function TastingRoomsLanding() {

   const params = useParams() as Params

   if(params?.roomId == null){
      return <div>Error</div>
   }

   const deepLink = `ginferno.app://tasting-rooms/${params.roomId}`

   return (
      <View style={{
         flex: 1,
         display: 'flex',
         alignItems: 'center',
         flexDirection: 'column',
         padding: 20,
         backgroundColor: '#002c52',
         height: '100%'
      }}>
         <Text style={{
            fontSize: 30,
            textAlign: 'center',  
            color: 'white',          
         }}>Welcome to GINferno's Virtual Tasting Rooms</Text>
         <img alt='logo' style={{
            marginTop: 20,
            marginBottom: 20
         }} src={LogoYellow}/>
         <View style={{
            marginVertical: 15
         }}>
            <a href={deepLink} style={{
               textDecoration: 'none'
            }}>
               <View
                  style={{
                     backgroundColor: 'rgb(255,213,0)',
                     justifyContent: 'center',
                     alignItems: 'center'
                  }}>
                  <Text style={{
                     color: '#002c52',
                     paddingVertical: 10,
                     fontWeight: 'bold'
                  }}>OPEN ROOM</Text>
               </View>
            </a>
            <Text style={{
               fontSize: 14,
               textAlign: 'center',
               color: 'white',
               marginVertical: 10
            }}>Click on below button to open the room on your iPhone</Text>

         </View>
         <Text style={{
            fontSize: 20,
            textAlign: 'center',
            color: 'white',
            marginTop: 15
         }}>If you do not have the app installed. Download the app now</Text>
         <View style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 10,
            justifyContent: 'center'
         }}>
            <TouchableOpacity onPress={() => onClickDownload('appstore')}>
               <img
                  alt='app-store'
                  style={{
                     width: 250,
                     height: 80,
                     marginBottom: 10,
                     marginRight: 10
                  }}
                  src={AppStore} />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onClickDownload('playstore')}>
               <img
                  alt='app-store'
                  style={{
                     width: 250,
                     height: 80,
                     marginBottom: 10
                  }}
                  src={PlayStore} />
            </TouchableOpacity>
         </View>
      </View>
   )

   function onClickDownload(source:'playstore' | 'appstore'){
      if(source === 'appstore'){
         window.open(`https://apps.apple.com/us/app/ginferno/id1511467045`)
      }else{
         window.open(`https://play.google.com/store/apps/details?id=app.ginferno.consumer`)
      }
   }
}
