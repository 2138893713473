import React from 'react';
import './App.css';
import TastingRoomsLanding from './TastingRoomsLanding'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <div style={{
      height:"100vh"
    }}>
      <Switch>
        <Route path="/tasting-rooms/:roomId">
          <TastingRoomsLanding />
        </Route>
      </Switch>
    </div>
  );
}

export default function Root() {
  return (
    <Router>
      <App />
    </Router>
  )
}
